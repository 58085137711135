<!-- 调货管理 -->
<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <div class="list_title">
      <div>创建时间</div>
      <div>调货店铺</div>
      <div>调货商品</div>
      <div>调货数量</div>
    </div>
    <!-- 提现记录表 -->
    <div class="box_index">
      <div class="list_index" v-for="(item, index) in list" :key="index">
        <div>{{ item.create_time }}</div>
        <div>{{ item.out_shop_name }}</div>
        <div>{{ item.product_name }}</div>
        <div>{{ item.num }}</div>
      </div>
    </div>
    <van-col span="24">
      <div class="bottom-con" style="margin: 3rem 16rem;">
        <van-pagination v-model="page" :total-items="total" :items-per-page="limit" @change="pageChange" force-ellipses />
        <van-button color="#1588F5" @click="add">新增调货</van-button>
      </div>
    </van-col>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
export default {
  name: "stockTransfer",
  components: {
    titlebar,
  },
  data() {
    return {
      name: "",
      list: [], // 商品分类
      limit: 10,
      page: 1,
      total: 0,
    }
  },
  methods: {
    async getList() {
      let res = await this.$api.getStockTransferList({
        shop_id: localStorage.shop_id,
        limit: this.limit,
        pageSize: this.page,
        type: 4,
      });
      if (res.code == 1) {
        this.list = res.data.list;
        this.total = res.data.count;
      }
    },
    pageChange(e) {
      this.page = e;
      this.getList();
    },
    add() {
      this.$router.push({
        path: "/addStockTransfer",
        query: { name: "addStockTransfer" },
      })
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.name = this.$route.query.name || ""; //拿到传过来的标题
    this.getList();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bottom-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.van-pagination__item--active {
  color: #fff !important;
  background-color: #1588f5 !important;
}
/deep/.van-pagination__item {
  color: #1588f5;
}
.list_title {
  height: 8rem;
  background: #f8f8f8;
  font-size: 2.6rem;
  font-weight: 400;
  color: #333333;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  align-items: center;
}
.box_index {
  // height: 40rem;
  flex: 1;
  overflow-y: scroll;
}
.box_index::-webkit-scrollbar {
  display: none;
}
.list_index {
  height: 7rem;
  font-size: 2.6rem;
  font-weight: 400;
  color: #333333;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  align-items: center;
  border-bottom: 0.1rem solid #e5e5e5;
}
.popup {
  width: 102.9rem;
  height: 48.7rem;
  background: #ffffff;
  text-align: -webkit-center;
}

.van-button--normal {
  font-size: 2.4rem;
}
</style>